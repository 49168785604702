<template>
  <gw-dialog v-model="dialog" :loading="bLocalLoading" :width="400">
    <template #activator="{ on }">
      <v-btn class="ml-2" color="primary" depressed v-on="on">
        <icon-invoice-alt class="mr-2" />
        {{ $t("contract.create.all.invoices") }}
      </v-btn>
    </template>

    <template #title>
      <v-toolbar color="transparent" flat>
        <v-toolbar-title>
          {{ $t("contract.create.invoices.title") }}
        </v-toolbar-title>
      </v-toolbar>
    </template>

    <template #prepend-actions>
      <v-btn
        class="ml-2"
        color="primary"
        text
        @click="onCreateContractInvoices"
      >
        {{ $t("start") }}
      </v-btn>
    </template>

    <v-form @submit.prevent>
      <gw-form-observer hide-bottom-actions hide-top-actions>
        <v-row>
          <v-col>
            <form-field-date-picker
              v-model="obData.date"
              :max="maxDate"
              :min="minDate"
            />
          </v-col>
        </v-row>
      </gw-form-observer>
    </v-form>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import ContractsMixin from "@/modules/contracts/mixins/ContractsMixin";
import GwDialog from "@/components/common/GwDialog.vue";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import dayjs from "dayjs";

interface CreateContractInvoicesData {
  date?: string;
  filters?: Record<string, any>;
}

@Component({
  components: { FormFieldDatePicker, GwDialog },
})
export default class CreateContractInvoicesDialog extends Mixins(
  ContractsMixin
) {
  dialog = false;
  obData: CreateContractInvoicesData = {};

  get minDate() {
    return dayjs().startOf("month").format("YYYY-MM-DD");
  }

  get maxDate() {
    return dayjs().endOf("month").format("YYYY-MM-DD");
  }

  @Watch("dialog")
  onToggleDialog(sValue: boolean) {
    if (sValue) {
      this.obData.date = dayjs().format("YYYY-MM-DD");
    }
  }

  async onCreateContractInvoices() {
    this.bLocalLoading = true;
    this.obData.filters = this.modelFilters;
    await this.onCreateInvoices(this.obData);
    this.bLocalLoading = false;
    this.dialog = false;
    this.emit("reload.index");
  }
}
</script>
